import React from "react";
import Moment from "react-moment";
import moment from "moment";
import Logo1 from "../PCBL_logo-01.png";
export const BASE_URL = process.env.REACT_APP_PUBLIC_URL; //Project Base URL
export const API_URL = process.env.REACT_APP_API_URL; // API Base URL
export const BASE_PATH = process.env.REACT_APP_BASE_PATH; //Project Base Path

//////////// Social Links ////////
export const FACEBOOK_URL = "https://www.facebook.com/pcblltd/";
export const TWITTER_URL = "https://twitter.com/PhillipsCarbon";
export const LINKEDIN_URL = "https://www.linkedin.com/company/1156753/";
export const YOUTUBE_URL =
  "https://www.youtube.com/channel/UC2f-l0XFe1G_chdERxn7ohQ";

///////////////// Google Recaptcha Key /////////////
export const RECAPTCHA_KEY = "6Lf7Q-EZAAAAALwAPsyhZM33WZNQfv6xcgrQsQZN";

////////////// Admin Email Address for which business enquiry mail will be send /////////
export const ADMIN_EMAIL_RUBBER_BLACK = "";
export const ADMIN_EMAIL_SPECIALITY_BLACK = "";
export const ADMIN_EMAIL_ONLINE_APPLICATION = "manoj.debnath@rpsg.in";

///////////// LOGO URL /////////////
export const LOGO_RSGP =
  "https://pcblltd.com/static/media/RPSG_Group.b7e08f8f.png";
//export const LOGO_PCBL = "https://pcblltd.com/static/media/psrg_logo_2.png";
// export const LOGO_PCBL = "https://pcblltd.com/static/media/pcbl_new_logo.png";
 export const LOGO_PCBL = "https://api.pcblltd.com/uploads/pcbl_chemical_logo_new_5806777e9a.png";
//export const LOGO_PCBL=Logo1;

///////////// Google Analytics Tracking ID /////////////
export const GA_TRACKING_ID = "";

export const dateFormater = (date, dFormat) => {
  return (
    <Moment format={dFormat} withTitle>
      {date}
    </Moment>
  );
};

export const gtmCurrentTime = () => {
  return moment().format("YYYY-MM-DD HH:mm:ss");
};

export const textTruncate = (str, length, ending) => {
  if (length == null) {
    length = 100;
  }
  if (ending === null || ending === "...") {
    ending = `<span><b>. . .</b></span>`;
  }
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending;
  } else {
    return str;
  }
};

export const isTextTruncateReadMore = (str, length) => {
  if (length == null) {
    length = 100;
  }
  if (str.length > length) {
    return true;
  } else {
    return false;
  }
};

export const NumericValue = (val) => {
  return val ? val : 0;
};
